/*eslint-disable*/
import React from "react"

import ParallaxSection from "components/ParallaxSection"
// nodejs library that concatenates classes
import classNames from "classnames"
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Accordion from "components/Accordion/Accordion.js"
import LinearProgress from '@material-ui/core/LinearProgress';
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Typography from "@material-ui/core/Typography"

import {Link} from "gatsby"


import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"

import { gql, useQuery } from "@apollo/client"

import SEO from "../components/Seo"
import useSiteMetadata from "../hooks/use-site-metadata"


import PageLayout from "../components/PageLayout"

const useStyles = makeStyles(productStyle)

const ListingPage = (props) => {

  const { cmsURL, baseImageURL, mediaURL } = useSiteMetadata()

  const title = props["*"]
  console.log(props)

  const LISTINGS_QUERY = gql`
    {
      propertiesCollection(filter: { title_slug: "${title}" }) {
        _id
        title
        title_slug
        image{
            path
        }
        content
        price
        gallery {
          path
          meta
        }
      }
    }
  `

  const classes = useStyles()

  const {loading, error, data} = useQuery(LISTINGS_QUERY)
  
  if (loading) return <LinearProgress />
  if (error) return `Error! ${error.message}`

  if (!data.propertiesCollection) {
    data.propertiesCollection = []
  }


  const images = data.propertiesCollection[0].gallery.map(image => ({
    original: mediaURL + image.path,
    thumbnail: mediaURL + image.path,
    // original: cmsURL + image.path,
    // thumbnail: cmsURL + image.path,

  }))


  const header_image = data.propertiesCollection[0].image
  ? mediaURL+"storage/uploads"+data.propertiesCollection[0].image.path
  // ? baseImageURL+data.listingsCollection[0].image.path
  : images[0].original

  return (
    <div className={classes.productPage}>
      <PageLayout>
      <SEO
          title={data.propertiesCollection[0].title}
          description={data.propertiesCollection[0].title}
          image={header_image}
          pathname={location.pathname}
        />
        <ParallaxSection
          image={header_image}
          filter="dark"
          className={classes.pageHeader}
        />

        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            <div className={classNames(classes.main, classes.mainRaised)}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom:"2rem"}}>
                <Link color="inherit" to="/">
                  Αρχική
                </Link>
                <Link color="inherit" to="/listings">
                  Πωλήσεις
                </Link>
                <Typography color="textPrimary">{data.propertiesCollection[0].title}</Typography>
              </Breadcrumbs>
              <GridContainer>
              <h2 className={classes.title}>
                    {data.propertiesCollection[0].title}
                  </h2>
                  
                  
                <GridItem>
                {/* <GridItem md={6} sm={6}> */}
                  <ImageGallery
                    showNav={false}
                    showFullscreenButton={true}
                    showPlayButton={false}
                    startIndex={0}
                    items={images}
                    // autoPlay={true}
                    useBrowserFullscreen={false}
                  />

                  
                </GridItem>
                <GridItem style={{marginTop:'2rem'}}>
                  <Typography variant="h6" style={{textAlign: 'center'}}>
                  Τιμη πωλησης: {data.propertiesCollection[0].price} €
                  </Typography>
                </GridItem> 
                
                
                <GridItem style={{margin:'3rem 0 3rem 0'}}>
                  {data.propertiesCollection[0].content}
                </GridItem>
                
              </GridContainer>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  )
}

export default ListingPage
